import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { brandingSelector } from 'src/selectors/selectors';

const CustomCSSBaseline = () => {
  const branding = useSelector(brandingSelector);

  useEffect(() => {
    let styleElement;

    if (branding?.customCSS) {
      styleElement = document.createElement('style');
      styleElement.type = 'text/css';
      styleElement.textContent = branding.customCSS;
      document.head.appendChild(styleElement);
    }

    return () => {
      if (styleElement) {
        document.head.removeChild(styleElement);
      }
    };
  }, [branding?.customCSS]);

  return null; // Does not render anything
};

export default CustomCSSBaseline;
