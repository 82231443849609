import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import Label from 'src/components/Label';

export function checkIsAdmin() {
  try {
    const token = localStorage?.getItem('accessToken');
    if (token) {
      return jwtDecode(token)?.isAdmin || false;
    }
    return false;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
}
export default function AdminBadge() {
  const isAdmin = useMemo(() => checkIsAdmin(), [
    localStorage?.getItem('accessToken')
  ]);

  return isAdmin ? (
    <Label icon="ri:admin-line" color="warning">
      Admin
    </Label>
  ) : null;
}
