import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { checkIsAdmin } from 'src/layouts/MainLayout/AdminBadge';
import { accountSelector, companySelector } from 'src/selectors/selectors';
import TwoFactorAuthCard from 'src/views/affiliate/Setting/TwoFactorAuthCard';
import Page from '../Page';

function TwoFactorAuthGuard({ children, hide = false }) {
  const account = useSelector(accountSelector);
  const company = useSelector(companySelector);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const isAdmin = useMemo(() => checkIsAdmin(), [
    localStorage?.getItem('accessToken')
  ]);

  const isLocked = useMemo(
    () =>
      !isAdmin &&
      company?.requireAffiliate2FA &&
      !account.user?.twoFactorAuthActive,
    [company?.requireAffiliate2FA, account.user?.twoFactorAuthActive, isAdmin]
  );

  if (isLocked) {
    return hide ? null : (
      <Page>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2">
              <FormattedMessage id="please_enable_2fa" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TwoFactorAuthCard sideBySide={isMdUp} />
          </Grid>
        </Grid>
      </Page>
    );
  }
  return children;
}

TwoFactorAuthGuard.propTypes = {
  children: PropTypes.any,
  showAlert: PropTypes.bool
};

export default TwoFactorAuthGuard;
