import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Badge,
  Button,
  Collapse,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip
} from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink, NavLink as RouterLink, useLocation } from 'react-router-dom';
import SiteTypeGuard, { SiteTypeEnum } from 'src/components/guards/BrokerGuard';
import { activeStyle } from '../SubBar';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: 'stretch',
    flexDirection: 'column'
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    '&:hover': {
      outline: 'none'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    minWidth: '20px',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  settingsIcon: {
    marginLeft: '80%',
    position: 'absolute'
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: `600 !important`
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  }
}));

const ListItemItem = styled(ListItem)(({ theme }) => ({
  display: 'block',
  paddingTop: 0,
  paddingBottom: 0
}));

const StyledButton = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: '10px 8px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: 0,
  width: '100%',
  gap: 10,
  '&:hover': {
    outline: 'none'
  },

  '&:focus': {
    outline: 'none'
  }
}));

const IconButtonSettingsIcon = styled(IconButton)(({ theme }) => ({
  marginLeft: '80%',
  position: 'absolute'
}));

const SpanTitle = styled(ListItemText)(({ theme }) => ({
  marginRight: 'auto'
}));

const ListItemItemLeaf = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  paddingTop: 0,
  paddingBottom: 0,
  alignItems: 'stretch',
  flexDirection: 'column'
}));

const ButtonButtonLeaf = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: '10px 8px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  fontWeight: theme.typography.fontWeightRegular,
  gap: 10,

  '&.depth-0': {
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    }
  }
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  toolTipText,
  inlineIcon,
  childItemsSize,
  onClick,
  notification = 0,
  isBeta = false,
  allowedTypes = [...Object.values(SiteTypeEnum)],
  id,
  ...rest
}) {
  const { pathname } = useLocation();

  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 8;

  const style = { paddingLeft };
  if (children) {
    return (
      <SiteTypeGuard allowedTypes={allowedTypes}>
        <ListItemItem
          sx={{ pl: depth, ...(pathname === href ? activeStyle : {}) }}
          disableGutters
          key={title}
          {...rest}
          title={title}
          id={id}
        >
          <Box display="flex">
            <StyledButton onClick={handleToggle} style={style}>
              <Badge color="warning" badgeContent={notification}>
                {Icon && (
                  <Icon
                    sx={{
                      minWidth: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 1
                    }}
                    size="20"
                  />
                )}
              </Badge>
              <SpanTitle
                title={title}
                primaryTypographyProps={{
                  variant: 'body2',
                  fontWeight: 500
                }}
              >
                {title}
              </SpanTitle>
              {childItemsSize ? (
                <ExpandMoreIcon
                  sx={{
                    transform: `rotateZ(${open ? 180 : 0}deg)`,
                    transition: theme =>
                      theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest
                      })
                  }}
                />
              ) : (
                ''
              )}
            </StyledButton>
            {inlineIcon ? (
              toolTipText ? (
                <Tooltip placement="top" title={toolTipText}>
                  <IconButtonSettingsIcon
                    onClick={inlineIcon.onClick ? inlineIcon.onClick : () => {}}
                    size="large"
                  >
                    <inlineIcon.icon />
                  </IconButtonSettingsIcon>
                </Tooltip>
              ) : (
                <IconButtonSettingsIcon
                  onClick={inlineIcon.onClick ? inlineIcon.onClick : () => {}}
                  component={RouterLink}
                  to={inlineIcon.href.toString()}
                  size="large"
                >
                  <inlineIcon.icon />
                </IconButtonSettingsIcon>
              )
            ) : (
              ''
            )}
          </Box>

          <Collapse in={Boolean(open)}>{children}</Collapse>
        </ListItemItem>
      </SiteTypeGuard>
    );
  }
  return onClick ? (
    <SiteTypeGuard allowedTypes={allowedTypes}>
      <ListItemItem
        id={id}
        sx={{ pl: depth }}
        disableGutters
        key={title}
        {...rest}
      >
        <Box display="flex">
          <StyledButton onClick={onClick} style={style}>
            <SpanTitle
              title={title}
              primaryTypographyProps={{
                variant: 'body2'
              }}
            >
              {title}
            </SpanTitle>
          </StyledButton>
        </Box>
      </ListItemItem>
    </SiteTypeGuard>
  ) : (
    <SiteTypeGuard allowedTypes={allowedTypes}>
      <ListItemItem
        id={id}
        sx={{ pl: depth }}
        disableGutters
        key={title}
        {...rest}
      >
        <Box display="flex">
          <StyledButton
            sx={pathname == href ? activeStyle : {}}
            component={NavLink}
            exact={false}
            style={style}
            to={href}
          >
            <Badge color="warning" badgeContent={notification}>
              {Icon && (
                <Icon
                  sx={{
                    minWidth: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 1
                  }}
                  size="20"
                />
              )}
            </Badge>
            <SpanTitle
              title={title}
              primaryTypographyProps={{
                variant: 'body2'
              }}
            >
              {title}
            </SpanTitle>{' '}
            {isBeta && <Badge color="info" badgeContent={'BETA'}></Badge>}{' '}
            <Box component="span" ml={1}>
              {/* {Info && (
              <Info
                counter={currentlyActiveInboxCounter}
                className={classes.info}
              />
            )} */}
            </Box>
          </StyledButton>
          {inlineIcon ? (
            toolTipText ? (
              <Tooltip placement="top" title={toolTipText}>
                <IconButton
                  onClick={inlineIcon.onClick ? inlineIcon.onClick : () => {}}
                  style={{ float: 'right' }}
                  size="large"
                >
                  <inlineIcon.icon />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton
                onClick={inlineIcon.onClick ? inlineIcon.onClick : () => {}}
                style={{ float: 'right' }}
                size="large"
              >
                <inlineIcon.icon />
              </IconButton>
            )
          ) : (
            ''
          )}
        </Box>
      </ListItemItem>
    </SiteTypeGuard>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  inlineIcon: PropTypes.object,
  childItemsSize: PropTypes.number,
  toolTipText: PropTypes.string
};

NavItem.defaultProps = {
  open: false
  // onClick: () => {
  // }
};

export default NavItem;
