import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom'; // Ensure you import Redirect correctly from react-router-dom
import { companySelector } from '../../selectors/selectors';

function MlmGuard({ children, level = 1, redirect = false, active = true }) {
  const {
    mlmActive,
    mlmLevel2Active,
    mlmLevel3Active,
    mlmLevel4Active,
    mlmLevel5Active,
    mlmLevel6Active,
    mlmLevel7Active,
    mlmLevel8Active,
    mlmLevel9Active,
    mlmLevel10Active
  } = useSelector(companySelector);

  const userCustomMlmSettingsObject = useSelector(
    state => state.account?.user?.userCustomMlmSettingsObject
  );

  const getActiveLevel = useCallback(() => {
    if (userCustomMlmSettingsObject?.customMlmEnabled) {
      switch (level) {
        case 1:
          return userCustomMlmSettingsObject.mlmActive;
        case 2:
          return userCustomMlmSettingsObject.mlmLevel2Active;
        case 3:
          return userCustomMlmSettingsObject.mlmLevel3Active;
        case 4:
          return userCustomMlmSettingsObject.mlmLevel4Active;
        case 5:
          return userCustomMlmSettingsObject.mlmLevel5Active;
        case 6:
          return userCustomMlmSettingsObject.mlmLevel6Active;
        case 7:
          return userCustomMlmSettingsObject.mlmLevel7Active;
        case 8:
          return userCustomMlmSettingsObject.mlmLevel8Active;
        case 9:
          return userCustomMlmSettingsObject.mlmLevel9Active;
        case 10:
          return userCustomMlmSettingsObject.mlmLevel10Active;
        default:
          return userCustomMlmSettingsObject.mlmActive;
      }
    } else {
      switch (level) {
        case 1:
          return mlmActive;
        case 2:
          return mlmLevel2Active;
        case 3:
          return mlmLevel3Active;
        case 4:
          return mlmLevel4Active;
        case 5:
          return mlmLevel5Active;
        case 6:
          return mlmLevel6Active;
        case 7:
          return mlmLevel7Active;
        case 8:
          return mlmLevel8Active;
        case 9:
          return mlmLevel9Active;
        case 10:
          return mlmLevel10Active;
        default:
          return mlmActive;
      }
    }
  }, [
    level,
    mlmActive,
    mlmLevel2Active,
    mlmLevel3Active,
    mlmLevel4Active,
    mlmLevel5Active,
    mlmLevel6Active,
    mlmLevel7Active,
    mlmLevel8Active,
    mlmLevel9Active,
    mlmLevel10Active,
    userCustomMlmSettingsObject
  ]);

  if (active && !getActiveLevel()) {
    if (redirect) {
      return <Redirect to="/app/reports/dashboard" />;
    }
    return null;
  }

  return children;
}

MlmGuard.propTypes = {
  children: PropTypes.node,
  level: PropTypes.number,
  redirect: PropTypes.bool
};

export default MlmGuard;
