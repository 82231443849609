import ajaxUtils from '../utils/ajaxUtils';
import { get } from '../utils/axios';
import { dateToLocalDateTime } from '../utils/utils';

// eslint-disable-next-line import/prefer-default-export
export const getWithdrawalReadyBalance = async () => {
  const response = await get(`${ajaxUtils.dashboard}/withdrawal-ready-balance`);
  return response?.data;
};

// eslint-disable-next-line import/prefer-default-export
export const getDashboardTopCampaigns = async timeRange => {
  const response = await get(`${ajaxUtils.dashboard}/topCampaigns`, {
    startDate: dateToLocalDateTime(timeRange?.[0]),
    endDate: dateToLocalDateTime(timeRange?.[1])
  });
  return response?.data;
};
export const getDashboardOverview = async timeRange => {
  const response = await get(`${ajaxUtils.dashboard}/overview`, {
    startDate: dateToLocalDateTime(timeRange?.[0]),
    endDate: dateToLocalDateTime(timeRange?.[1])
  });
  return response?.data;
};
export const getDashboardLatestConversions = async () => {
  const response = await get(`${ajaxUtils.dashboard}/latestConversions`);
  return response?.data;
};

export const getDashboardLatestLeads = async () => {
  const response = await get(`${ajaxUtils.dashboard}/latestLeads`);
  return response?.data;
};

export const getDashboardJoinedCampaignPayoutAutomationTiersProgressBar = async (
  timeRange,
  campaignSerialOid
) => {
  const response = await get(`${ajaxUtils.dashboard}/tiers-progress-bar`, {
    startDate: dateToLocalDateTime(timeRange?.[0]),
    endDate: dateToLocalDateTime(timeRange?.[1]),
    campaignSerialOid
  });
  return response?.data;
};
export const getDashboardChart = async timeRange => {
  const response = await get(`${ajaxUtils.dashboard}/chart`, {
    startDate: dateToLocalDateTime(timeRange?.[0]),
    endDate: dateToLocalDateTime(timeRange?.[1])
  });
  return response?.data;
};

export const getDashboardSymbolsGraph = async (timeRange, filters) => {
  const response = await get(`${ajaxUtils.dashboard}/symbols`, {
    startDate: dateToLocalDateTime(timeRange?.[0]),
    endDate: dateToLocalDateTime(timeRange?.[1]),
    ...filters
  });
  return response?.data;
};

export const getDashboardGeoGraph = async (timeRange, type) => {
  const response = await get(`${ajaxUtils.dashboard}/geo`, {
    startDate: dateToLocalDateTime(timeRange?.[0]),
    endDate: dateToLocalDateTime(timeRange?.[1]),
    type
  });
  return response?.data;
};
