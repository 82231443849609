export const CustomFieldTypeEnum = {
  TEXT_AREA: { name: 'text_area', oid: 0 },
  CHECK_BOX: { name: 'check_box', oid: 1 },
  INPUT: { name: 'input', oid: 2 },
  SELECT: { name: 'select', oid: 3 },
  PHONE: { name: 'phone_number', oid: 4 },
  DATE_PICKER: { name: 'date_picker', oid: 5 }
};

// returns the matching component name by enum oid
export const getCustomFieldTypeByOid = paramOid => {
  let component = '-';
  // eslint-disable-next-line array-callback-return,consistent-return
  Object.entries(CustomFieldTypeEnum).map(([key, value]) => {
    if (value.oid === paramOid) {
      component = value;
    }
  });
  return component;
};
