import { Box, Link } from '@mui/material';
import Iconify from './iconify';

const ContactLink = ({ icon, href, label, target = '_self' }) => {
  if (!label) return null;

  return (
    <Box display="flex" gap={1} alignItems="center">
      <Box>
        <Iconify icon={icon} />
      </Box>
      <Link
        variant="body2"
        sx={{
          textDecoration: 'none',
          color: theme => `${theme.palette.text.primary} !important`,
          wordBreak: 'break-word',
          alignSelf: 'normal'
        }}
        href={href}
        target={target}
        rel={target === '_blank' ? 'noopener' : undefined}
      >
        {label}
      </Link>
    </Box>
  );
};

export default ContactLink;
