import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// eslint-disable-next-line camelcase
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  DateRangePicker,
  SingleInputDateRangeField
} from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { handleTimeInFormat } from 'src/utils/utils';
import { DEFAULT_DATE_FORMAT } from '../utils/staticObjects';

const useStyles = makeStyles(() => ({
  pointer: {
    cursor: 'pointer',
    '&::-webkit-calendar-picker-indicator': {
      cursor: 'pointer'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));
export const DateShortcutsEnum = {
  THIS_WEEK: {
    label: 'This Week',
    getValue: () => [moment().startOf('week'), moment().endOf('week')]
  },
  LAST_WEEK: {
    label: 'Last Week',
    getValue: () => {
      const prevWeek = moment().subtract(7, 'day');
      return [prevWeek.startOf('week'), cloneDeep(prevWeek).endOf('week')];
    }
  },
  LAST_7_DAYS: {
    label: 'Last 7 Days',
    getValue: () => [
      moment()
        .startOf('day')
        .subtract(6, 'day'),
      moment().endOf('day')
    ]
  },
  LAST_30_DAYS: {
    label: 'Last 30 Days',
    getValue: () => [
      moment()
        .startOf('day')
        .subtract(29, 'day'),
      moment().endOf('day')
    ]
  },
  CURRENT_MONTH: {
    label: 'Current Month',
    getValue: () => [moment().startOf('month'), moment().endOf('month')]
  },
  LAST_MONTH: {
    label: 'Last Month',
    getValue: () => [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
  },
  CURRENT_YEAR: {
    label: 'Current Year',
    getValue: () => {
      const startOfCurrentYear = moment().startOf('year');
      return [startOfCurrentYear, cloneDeep(startOfCurrentYear).endOf('year')];
    }
  },
  TODAY: {
    label: 'Today',
    getValue: () => [moment().startOf('day'), moment().endOf('day')]
  },
  LAST_YEAR: {
    label: 'Last Year',
    getValue: () => [
      moment()
        .subtract(1, 'year')
        .startOf('year'),
      moment()
        .subtract(1, 'year')
        .endOf('year')
    ]
  },
  LAST_365_DAYS: {
    label: 'Last 365 Days',
    getValue: () => [
      moment()
        .startOf('day')
        .subtract(364, 'day'),
      moment().endOf('day')
    ]
  },
  ALL_TIME: {
    label: 'All Time',
    getValue: () => [
      moment('1971-01-01', 'YYYY-MM-DD').startOf('day'),
      moment().endOf('year')
    ]
  }
};

export const dateShortcutsItems = Object.values(DateShortcutsEnum);

function DateRangepicker({
  label,
  customValidation,
  wide,
  disabled,
  value,
  setValue,
  isSingle = true,
  sx
}) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const intl = useIntl();
  // const translatedLabel = intl.formatMessage({
  //   id: camelCaseTo_under_score(label)
  // });
  const userTimeFormat = useSelector(
    state => state?.account?.user?.personalization?.timeFormat
  );
  const dateFormat = handleTimeInFormat(
    userTimeFormat || DEFAULT_DATE_FORMAT,
    true
  );
  let hasError;
  let errMsg;
  // if (customValidation !== undefined) {
  //   const err = customValidation();
  //   hasError = !err.isValid();
  //   errMsg = intl.formatMessage({ id: err.getMsg() });
  // }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateRangePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        sx={
          wide
            ? {
                width: '100%',
                '& .MuiInputBase-root': {
                  backgroundColor: theme => theme?.palette?.background?.paper
                }
              }
            : {
                '& .MuiInputBase-root': {
                  backgroundColor: theme => theme?.palette?.background?.paper
                }
              }
        }
        value={value}
        format={dateFormat}
        InputProps={{
          classes: { input: classes.pointer }
        }}
        localeText={{
          start: intl.formatMessage({ id: 'start_date' }),
          end: intl.formatMessage({ id: 'end_date' })
        }}
        slotProps={{
          shortcuts: {
            items: dateShortcutsItems
          }
        }}
        slots={isSingle ? { field: SingleInputDateRangeField } : null}
        onChange={val => {
          setValue(() => {
            const start = val[0] ? moment(val[0]).startOf('d') : null;
            const end = val[1] ? moment(val[1]).endOf('d') : null;
            return [start, end];
          });
        }}
      />
    </LocalizationProvider>
  );
}

DateRangepicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  open: PropTypes.bool,
  wide: PropTypes.bool,
  customValidation: PropTypes.func,
  onDatePickerChange: PropTypes.func,
  setOpen: PropTypes.func,
  setValue: PropTypes.func,
  disabled: PropTypes.bool
};
export default DateRangepicker;
