import compareDesc from 'date-fns/compareDesc';
import { ValidationError } from '../classes/ValidationError';
import { campaignSettings } from './enums/campaignsEnums';

const emailRegex = /^[a-zA-Z0-9_!#$%&’*+\/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/;
const firstNameRegex = /(?=^.{2,20}$)^[a-zA-Z\u0590-\u05fe()\-'\s]+$/im;
const lastNameRegex = /(?=^.{2,20}$)^[a-zA-Z\u0590-\u05fe()\-'\s]+$/im;
const hexColorRegex = /^#[a-fA-F0-9]+/;
const userPasswordPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{10,20}$/;
const alphaNumericAndSeparators = new RegExp('^[a-zA-Z0-9- _@.]+$', 'mi');

export const urlWithMacrosRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/={}]*)$/;

export function isFirstNameValid(userName) {
  // this will enables to change only one filed at settings null protection is from formvik required tag
  if (userName === undefined) {
    return true;
  }
  if (userName.includes('..')) {
    return false;
  }
  return firstNameRegex.test(userName);
}
export function isLastNameValid(lastName) {
  // this will enables to change only one filed at settings null protection is from formvik required tag
  if (lastName === undefined) {
    return true;
  }
  if (lastName.includes('..')) {
    return false;
  }
  return lastNameRegex.test(lastName);
}

export function isHexColor(colorString) {
  return colorString && hexColorRegex.test(colorString);
}

export function isUserPasswordValid(password) {
  return userPasswordPattern.test(String(password));
}

export function isContainsSpacialChars(value) {
  return alphaNumericAndSeparators.test(String(value).toLowerCase());
}

export function payoutIntegerFieldValidation(errors, field, payoutIndex) {
  if (
    errors[campaignSettings.PAYOUTS] !== undefined &&
    errors[campaignSettings.PAYOUTS].length > 0
  ) {
    const payoutErrorArr = field.split('.');
    const specificField = payoutErrorArr[1];
    const payoutBlocksArr = errors[campaignSettings.PAYOUTS];
    let msg;
    // eslint-disable-next-line array-callback-return,consistent-return
    const valid = !payoutBlocksArr.some(errBlock => {
      if (errBlock) {
        return Object.values(errBlock).some(errField => {
          if (errField.includes(`[${payoutIndex}].${specificField}`)) {
            msg = errField.includes('`NaN`')
              ? 'Should be a number'
              : 'Max size exceeded';
            return true;
          }
          return false;
        });
      }
    });
    return new ValidationError(valid, valid ? 'valid' : msg);
  }
  return new ValidationError(true, 'valid');
}
// true = valid
export const ipPreValidation = event => {
  const regex = new RegExp('^([0-9., ]*)+$');
  const dotOrDigit = regex.test(event.target.value);
  return dotOrDigit || '';
};

export const numberPreValidation = event => {
  const regex = new RegExp('^([0-9]*)+$');
  const dotOrDigit = regex.test(event.target.value);
  const numberValue = Number(event.target.value);
  // eslint-disable-next-line no-restricted-globals
  return !!((dotOrDigit || '') && numberValue < 2147483646);
};

// in what format the dates come in?
export const endDateValidation = (startDate, endDate) => {
  const valid = compareDesc(new Date(startDate), new Date(endDate)) === 1;
  return new ValidationError(valid, valid ? 'valid' : 'end_date_is_invalid');
};

export const validateIps = ips => {
  if (ips === undefined || ips === '') {
    return new ValidationError(true, 'valid');
  }
  ips = ips.trim();
  const regexIp = new RegExp(
    '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
  );
  if (ips.indexOf(',') === -1) {
    return new ValidationError(
      regexIp.test(ips),
      regexIp.test(ips) ? 'valid' : 'ip_is_invalid'
    );
  }
  const ipArr = ips.split(',').map(item => item.trim());

  const hasDuplicates = new Set(ipArr).size !== ipArr.length;
  if (hasDuplicates) {
    return new ValidationError(false, 'ip_should_be_unique');
  }

  for (let i = 0; i < ipArr.length; i++) {
    if (ipArr[i] !== '') {
      if (regexIp.test(ipArr[i]) === false) {
        return new ValidationError(false, 'one_of_ips_invalid');
      }
    }
  }
  return new ValidationError(true, 'valid');
};
export default function isValidAddress(emailAddress) {
  return emailRegex.test(String(emailAddress).toLowerCase());
}
