import ajaxUtils from '../utils/ajaxUtils';
import { get, post, put } from '../utils/axios';
import { COMPANY_BRANDING } from './companyActions';

// eslint-disable-next-line import/prefer-default-export
export const getCurrentBrandingRedux = () => {
  const request = get(`${ajaxUtils.companyActions.branding}/current`);
  return dispatch => {
    request
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: COMPANY_BRANDING,
            payload: response.data
          });
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
};

export const getCurrentBranding = async () => {
  const request = await get(`${ajaxUtils.companyActions.branding}/current`);
  return request?.data;
};

export const createCustomDomain = async customDomain => {
  const response = await post(
    `${ajaxUtils.companyActions.branding}/custom-domain`,
    null,
    customDomain
  );
  return response?.data;
};

export const getTrackingDomainsOptions = async () => {
  const request = await get(
    `${ajaxUtils.companyActions.branding}/custom-domain/options`
  );
  return request?.data;
};
export const getTrackingDomains = async paginationModel => {
  const request = await get(
    `${ajaxUtils.companyActions.branding}/custom-domain`,
    paginationModel
  );
  return request?.data;
};

export const updateCustomDomain = async customDomain => {
  const response = await put(
    `${ajaxUtils.companyActions.branding}/custom-domain`,
    null,
    customDomain
  );
  return response?.data;
};
