import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  get2faStatus,
  toggle2fa,
  update2faDataOnRedux
} from 'src/actions/userActions';
import Label from 'src/components/Label';
import { displayUpdateSnackbar } from 'src/utils/utils';
import * as yup from 'yup';

export default function TwoFactorAuthCard({ sideBySide = false }) {
  const intl = useIntl();
  const [twoFactor, setTwoFactor] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    get2faStatus().then(s => setTwoFactor(s));
  }, []);

  const schema = yup.object().shape({
    token: yup
      .string()
      .length(6, '6 digits code required')
      .required('Token is required')
  });
  const handleConfirm = async ({ token }) => {
    try {
      await toggle2fa(token);
      displayUpdateSnackbar(enqueueSnackbar, intl, true);
    } catch (e) {
      console.log(e);
      const msg = e?.response?.data?.message;
      enqueueSnackbar(
        intl.formatMessage({
          id: msg ?? 'general_error'
        }),
        {
          variant: 'error',
          autoHideDuration: 2200
        }
      );
    } finally {
      get2faStatus().then(s => {
        setTwoFactor(s);
        update2faDataOnRedux(s?.active);
      });
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema)
  });
  return (
    <Card>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', gap: 1 }}>
            {intl.formatMessage({ id: '2fa' })}
            <Label color={twoFactor?.active ? 'success' : 'warning'}>
              {intl.formatMessage({
                id: twoFactor?.active ? 'active' : 'inactive'
              })}
            </Label>
          </Box>
        }
      />
      <Divider />
      <CardContent>
        {!twoFactor?.active && twoFactor?.qrImage && (
          <Box sx={sideBySide ? { display: 'flex' } : {}}>
            <Box sx={{ direction: 'ltr /* @noflip */', p: 1, m: 1 }}>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'two_factor_auth_text',
                    defaultMessage:
                      '<strong>Two-factor Authenticator App</strong>: Use an' +
                      '                  Authenticator App as your Two-Factor Authentication (2FA).' +
                      '                  When you sign in, you’ll be required to use the security code' +
                      '                  provided by your Authenticator App.' +
                      '                  <br />' +
                      '                  If you opt to use an authenticator app for 2FA, these' +
                      '                  third-party common authenticator apps can be found in your' +
                      '                  mobile device app store:' +
                      '                  <ul>' +
                      '                    <li>Google Authenticator</li>' +
                      '                    <li>LastPass Authenticator</li>' +
                      '                    <li>Microsoft Authenticator</li>' +
                      '                    <li>Authy</li>' +
                      '                  </ul>'
                  })
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 1,
                m: 1,
                width: '100%',
                alignItems: 'center'
              }}
            >
              <img
                src={`data:image/jpeg;base64,${twoFactor?.qrImage}`}
                width={sideBySide ? '50%' : '100%'}
                alt="QR"
              />
              {isMobile && (
                <Link href={twoFactor?.uri} sx={{ p: 1, textAlign: 'center' }}>
                  {' '}
                  {intl.formatMessage({ id: 'open_in_app' })}
                </Link>
              )}
            </Box>
          </Box>
        )}
        <form onSubmit={handleSubmit(handleConfirm)}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Controller
              name="token"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={intl.formatMessage({ id: 'enter_6_digit_code' })}
                  type="number"
                  InputProps={{ maxLength: 6 }}
                  fullWidth
                  error={!!errors?.token}
                  helperText={errors?.token?.message}
                />
              )}
            />
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="secondary"
            >
              <FormattedMessage id={twoFactor?.active ? 'disable' : 'enable'} />
            </LoadingButton>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}
