export const TimePeriodEnum = {
  CUSTOM: {
    key: 'custom',
    value: 1
  },
  TODAY: {
    key: 'today',
    value: 2
  },
  LAST_WEEK: {
    key: 'last_week',
    value: 3
  },
  LAST_TWO_WEEKS: {
    key: 'last_two_weeks',
    value: 4
  },
  LAST_MONTH: {
    key: 'last_month',
    value: 5
  },
  LAST_THREE_MONTHS: {
    key: 'last_three_months',
    value: 6
  },
  LAST_SIX_MONTHS: {
    key: 'last_six_months',
    value: 7
  },
  LAST_YEAR: {
    key: 'last_year',
    value: 8
  }
};

export const SubIdEnum = {
  NOT_SELECTED: {
    key: 'not_selected',
    value: 0
  },
  SUB_1: {
    key: 'sub_1',
    value: 1
  },
  SUB_2: {
    key: 'sub_2',
    value: 2
  },
  SUB_3: {
    key: 'sub_3',
    value: 3
  },
  SUB_4: {
    key: 'sub_4',
    value: 4
  },
  SUB_5: {
    key: 'sub_5',
    value: 5
  },
  SUB_6: {
    key: 'sub_6',
    value: 6
  },
  SUB_7: {
    key: 'sub_7',
    value: 7
  },
  SUB_8: {
    key: 'sub_8',
    value: 8
  }
};

export const ConversionStatusEnum = {
  APPROVED: {
    key: 'approved',
    label: 'approved',
    value: 0
  },
  HOLD: {
    key: 'hold',
    label: 'hold',
    value: 1
  },
  PENDING: {
    key: 'pending',
    label: 'pending',
    value: 2
  },
  DENIED: {
    key: 'denied',
    label: 'denied',
    value: 3
  },
  REVISION: {
    key: 'revision',
    value: 4
  },
  TEST: {
    key: 'test',
    value: 5
  }
};

const groupByDayMonthYearEnum = {
  day: {
    key: 'day',
    value: 'day',
    icon: 'mdi:calendar-today-outline'
  },
  month: {
    key: 'month',
    value: 'month',
    icon: 'material-symbols:calendar-month-outline'
  },
  year: {
    key: 'year',
    value: 'year',
    icon: 'fluent-mdl2:calendar-year'
  }
};

export const GroupByEnum = {
  campaign: {
    key: 'campaign',
    value: 'campaign'
  },
  sub1: {
    key: 'SUB1',
    value: 'sub1'
  },
  sub2: {
    key: 'SUB2',
    value: 'sub2'
  },
  sub3: {
    key: 'SUB3',
    value: 'sub3'
  },
  utmSource: {
    key: 'utm_source',
    value: 'utmSource'
  },
  utmMedium: {
    key: 'utm_medium',
    value: 'utmMedium'
  },
  utmContent: {
    key: 'utm_content',
    value: 'utmContent'
  },
  ...groupByDayMonthYearEnum
};

export const GroupByEnumNavBar = {
  campaign: {
    ...GroupByEnum.campaign,
    icon: 'material-symbols:campaign-outline',
    children: {
      ...groupByDayMonthYearEnum
    }
  },
  sub1: {
    ...GroupByEnum.sub1,
    icon: 'mynaui:one',
    children: {
      ...groupByDayMonthYearEnum
    }
  },
  sub2: {
    ...GroupByEnum.sub2,
    icon: 'mynaui:two',
    children: {
      ...groupByDayMonthYearEnum
    }
  },
  sub3: {
    ...GroupByEnum.sub3,
    icon: 'mynaui:three',
    children: {
      ...groupByDayMonthYearEnum
    }
  },
  utmSource: {
    ...GroupByEnum.utmSource,
    icon: 'mdi:web',
    children: {
      ...groupByDayMonthYearEnum
    }
  },
  utmMedium: {
    ...GroupByEnum.utmMedium,
    icon: 'mdi:message-text',
    children: {
      ...groupByDayMonthYearEnum
    }
  },
  utmContent: {
    ...GroupByEnum.utmContent,
    icon: 'mdi:file-document',
    children: {
      ...groupByDayMonthYearEnum
    }
  },
  custom: {
    key: 'custom_group_by',
    value: 'custom',
    icon: 'carbon:chart-custom'
  }
};

export function findGroupByEnumByValue(value) {
  if (value === GroupByEnumNavBar.custom.value) return GroupByEnumNavBar.custom;
  for (const key in GroupByEnum) {
    if (GroupByEnum[key].value === value) {
      return GroupByEnum[key];
    }
  }
  return null;
}

export const getConversionStatusKeyByValue = value => {
  const statusColor = Object.entries(ConversionStatusEnum).filter(
    ([key, objValue]) => objValue.value === value
  );
  if (statusColor.length > 0) {
    return statusColor[0][0];
  }
  return ConversionStatusEnum.PENDING.key?.toUpperCase();
};

export const getStatusLabelByValue = value =>
  Object.values(ConversionStatusEnum).filter(
    status => status.value === value
  )[0].key;
