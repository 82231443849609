import { Skeleton } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  brandingSelector,
  companyLogoOverrideSelector,
  domainSelector
} from 'src/selectors/selectors';
import { getCurrentBrandingRedux } from '../actions/brandingActions';
import { THEMES } from '../constants';
import useSettings from '../hooks/useSettings';

function Logo({
  color = 'white',
  isNegativeMode = false,
  onlyTracknow = false,
  campaignLogoOverride = false,
  ...props
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const companyLogoOverride = useSelector(companyLogoOverrideSelector);
  const branding = useSelector(brandingSelector);
  const domain = useSelector(domainSelector);
  const logoPath = useMemo(() => {
    return domain?.domainType === 'CAMPAIGN_DOMAIN'
      ? branding?.brandLogoFilePath
      : campaignLogoOverride && companyLogoOverride
      ? companyLogoOverride
      : branding?.brandLogoFilePath;
  }, [
    domain?.domainType,
    branding?.brandLogoFilePath,
    companyLogoOverride,
    campaignLogoOverride
  ]);
  const params = new URLSearchParams(window.location.search);
  // REACT_APP_CAMPAIGN_LOGO_BASE_URL

  const baseImagesUrl = useMemo(() => {
    return domain?.domainType === 'CAMPAIGN_DOMAIN' ||
      (campaignLogoOverride && companyLogoOverride)
      ? process.env.REACT_APP_CAMPAIGN_LOGO_BASE_URL
      : process.env.REACT_APP_BRAND_LOGO_BASE_URL;
  }, [domain?.domainType, companyLogoOverride, campaignLogoOverride]);

  const config = useSelector(state => state?.config);
  const inMainDomain = config?.domain?.mainDomain;
  const isMobileNavOpen = config?.isMobileNavOpen;

  const fetchData = async () => {
    if (!branding && !inMainDomain) {
      dispatch(getCurrentBrandingRedux());
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { settings } = useSettings();
  // if (isMobileNavOpen) {

  color =
    (isNegativeMode || isMobileNavOpen) && settings.theme === THEMES.LIGHT.name
      ? 'dark'
      : 'white';
  // }

  const defaultLogo = `/static/logos/tracknow-logo-${color}.webp`;
  const imgUrl =
    !!campaignLogoOverride && !!companyLogoOverride
      ? `${baseImagesUrl}${companyLogoOverride}`
      : !onlyTracknow && logoPath
      ? `${baseImagesUrl}${logoPath}`
      : defaultLogo;

  return (
    Boolean(logoPath || onlyTracknow) &&
    (!loading ? (
      <img
        id="logo"
        alt={domain?.domainHost}
        src={imgUrl}
        style={{ maxWidth: 200, maxHeight: 50 }}
        {...props}
      />
    ) : (
      <Skeleton id="logo" sx={{ width: '200px', height: '50px' }} />
    ))
  );
}

export default Logo;
