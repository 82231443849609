import jwtDecode from 'jwt-decode';
// import { instance as axios2, post } from 'src/utils/axios';
import axios from 'axios';
import { store } from 'src';
import { LOGOUT, updateUserDataOnRedux } from 'src/actions/userActions';
import { post } from 'src/utils/axios';
import {
  INVALID_2FA_TOKEN,
  MISSING_2FA_TOKEN,
  PRIVACY_POLICY_NEEDS_APPROVAL,
  TERMS_AND_CONDITIONS_NEEDS_APPROVAL
} from 'src/utils/staticObjects';
import {
  openCustomMessage,
  publicRedirectToHome
} from '../components/GlobalErrorModal';
import ajaxUtils from '../utils/ajaxUtils';
import userErrorMessageEnum from '../utils/enums/userErrorMessageEnum';
var failedRequests = 0;
class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          const msg = error.response?.data?.message;
          if (
            msg == INVALID_2FA_TOKEN ||
            msg == MISSING_2FA_TOKEN ||
            msg == TERMS_AND_CONDITIONS_NEEDS_APPROVAL ||
            msg == PRIVACY_POLICY_NEEDS_APPROVAL
          ) {
            throw error;
          }
          failedRequests = failedRequests++;
          console.log(failedRequests);
          if (failedRequests > 3) {
            console.log('logingOut');
            failedRequests = 0;
            this.setSession(null);

            if (onLogout) {
              onLogout();
            }
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      // if user logged in and then close browser for too long there  isnt any logic showing him error message
      const timeGap = Math.abs((currentTime - decoded.exp) / 1000);
      if (decoded.exp > currentTime && timeGap > 30 && timeGap < 60) {
        openCustomMessage({
          message: userErrorMessageEnum.jwtExpired,
          onClose: publicRedirectToHome
        });
      }
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = async (email, password) => {
    this.setSession(null);
    const response = await axios({
      method: 'post',
      url: `${ajaxUtils.userActions.login}`,
      headers: {
        Authorization: ''
      },
      params: {
        email,
        password
      }
    });
    const { token, data: user } = response.data;
    this.setSession(token);
    this.setTimoutRefreshToken(token);
    return user;
  };

  adminLoginWithEmailAndPassword = async (email, merchantSecret) => {
    const response = await axios({
      method: 'post',
      url: `${ajaxUtils.userActions.adminLogin}`,
      headers: {
        Authorization: ''
      },
      params: {
        email,
        merchantSecret
      }
    });
    const { token, data: user } = response.data;
    this.setSession(token);
    this.setTimoutRefreshToken(token);
    return user;
  };

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      const request = post(ajaxUtils.userActions.loginWithToken, null, null);
      request
        .then(response => {
          const { data: user } = response;
          if (user) {
            this.setTimoutRefreshToken(this.getAccessToken());
            updateUserDataOnRedux(user);
            resolve(user);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = async accessToken => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('persist:root');
      delete axios.defaults.headers.common.Authorization;
      await store.dispatch({ type: LOGOUT });
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = accessToken => {
    try {
      if (!accessToken) {
        return false;
      }

      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;

      return decoded.exp > currentTime;
    } catch (e) {
      return false;
    }
  };

  isAuthenticated = () => !!this.getAccessToken();

  refreshToken = async () => {
    try {
      const {
        data: { token }
      } = await post(`${ajaxUtils.userActions.refreshToken}`);
      this.setSession(token);
      this.setTimoutRefreshToken(token);
    } catch (e) {
      console.warn(e);
    }
  };

  setTimoutRefreshToken = accessToken => {
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    let remainingTimeForExpiration = decoded.exp - currentTime;
    // refresh token 1 minute before expiration
    if (remainingTimeForExpiration - 60 < 0) {
      remainingTimeForExpiration = 120;
    }
    setTimeout(this.refreshToken, (remainingTimeForExpiration - 60) * 1000);
  };
}

const authService = new AuthService();

export default authService;
