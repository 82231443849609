export const domainSelector = state => state?.config?.domain;
export const accountSelector = state => state?.account;
export const accountCreationDateSelector = state =>
  state?.account?.user?.creationDate;
export const permissionsSelector = state =>
  state?.account?.user?.userPermissions;
export const companySelector = state => state.company;
export const brandingSelector = state => state?.company?.branding;
export const companyLogoOverrideSelector = state =>
  state?.company?.companyLogoOverride;
